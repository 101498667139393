import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Helmet from "react-helmet"

import { Col, Row } from "antd"
import "antd/dist/antd.css"
import * as styles from "./index.module.scss"

import { Ipage } from "@src/pages"

function Banner() {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "about_us/banner.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
      banner_mobile: file(relativePath: { eq: "about_us/banner_mobile.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
    }
  `)

  const image = getImage(data.banner.childImageSharp.gatsbyImageData)
  const image_mobile = getImage(
    data.banner_mobile.childImageSharp.gatsbyImageData
  )
  return (
    <div className={styles.banner}>
      <Row>
        <Col md={24} sm={0} xs={0}>
          {image && <GatsbyImage image={image} alt="banner" />}
        </Col>
        <Col md={0} sm={24} xs={24}>
          {image_mobile && <GatsbyImage image={image_mobile} alt="banner" />}
        </Col>
      </Row>
      <h1>保持创业初心，客户价值第一</h1>
    </div>
  )
}
interface Ititle {
  children: string
  style?: any
}
function Title({ children }: Ititle) {
  return <div className={styles.title}>{children}</div>
}

function Text({ children, style }: Ititle) {
  return (
    <div className={styles.text} style={{ ...style }}>
      {children}
    </div>
  )
}

interface IrenderItem {
  time: string | number
  title: string
  details: string[]
}
function RenderItem({ time, title, details }: IrenderItem) {
  return (
    <div className={styles.flex_item}>
      <div className={styles.time}>{time}</div>
      <div className={styles.intro}>{title}</div>
      <ul className={styles.details}>
        {details.map(item => (
          <li className={styles.detail} key={item}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  )
}

const renderData = [
  {
    time: "2012",
    title: "国内营销企业服务",
    details: ["腾讯广告代理商", "百度、搜狗、新浪等广告代理商"],
  },
  {
    time: "2014",
    title: "企业海外营销",
    details: ["Bing中国区代理商", "Yandex中国区代理商", "Google优秀合作伙伴"],
  },
  {
    time: "2016",
    title: "数字化&智能营销",
    details: ["程序化创意系统", " AI智能投放系统", "大数据选品系统"],
  },
  {
    time: "2018",
    title: "一站式出海营销服务",
    details: ["培训学院", "供应链生态"],
  },
]

export default function AboutUs(params: Ipage) {
  return (
    <div className={styles.about_us}>
      <Helmet title="关于我们-Yunstar" />
      <Banner />
      <div className={styles.container}>
        <Title>关于Yunstar</Title>
        <div className={styles.text_container}>
          <Text>
            Yunstar在创立初期凭借专业团队及敏锐的嗅觉，在不到2年时间内便从国内互联网广告代理商拓展到跨境电商业务，开始布局海外营销战略。在中国电商独立站出海潮的时代背景下，立足中国卖家核心需求，我们希望能帮助商户快速占领海外市场，并积极提高其工作流程效率。为帮助出海品牌快速提升全球竞争力，积极与Google、Bing、Yahoo、Yandex、LinkedIn等全球知名互联网公司达成深度合作，助力中国制造拥抱全球市场。
          </Text>
          <Text>
            我们坚持投入高质量资源，成立技术研发团队，以前沿创新科技为支撑，开发数字化营销和AI智能营销，驱动建设程序化创意系统、大数据选品系统、智能投放广告系统等多元服务工具，超前洞察客户需求，赋能品牌出海大捷。
          </Text>
          <Text>
            发展至今，我们已经建立起一个一体化的电子商务平台，功能强大、技术先进且操作界面友好。同时，Yunstar正持续打造平台商业生态，不断迭代产品，把最佳实践赋能给更多卖家。我们十分感谢团队提供的专业知识和创造力，在出海独立站的黄金时代，使我们在行业中脱颖而出。我们将秉承初心，全力协助中国企业拥抱全球，创造更多属于我们自己的品牌。
          </Text>
        </div>
      </div>
      {/* <div style={{ background: "#F9FBFE" }}>
        <div className={styles.development}>
          <Title>Yunstar发展历程</Title>
          <Text style={{ textAlign: "center", marginTop: "20px" }}>
            有志者，事竟成。在助力品牌出海的航路上，我们永不止步、始终探索、一往无前。
          </Text>
          <div className={styles.flex_box}>
            {renderData.map(item => {
              return <RenderItem {...item} key={item.time} />
            })}
          </div>
        </div>
      </div> */}
    </div>
  )
}
